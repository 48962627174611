const config = {
    brand: {
        name: ['De','Beleggings-','en Spaarbank'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Wall Street',
            subtitle: 'Beleg op Wall Street met je smartphone vanuit je luie stoel.'
        }
    ]
}

export { config }